import React from 'react';
import './AppSwiper.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {A11y, EffectCoverflow, Pagination} from 'swiper';
import {RiStarFill} from 'react-icons/ri'
import PayQrCode from "./PayQrCode";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import Nav from "./Nav";
import {connect} from "redux-zero/react";
import actions from "../store/actions";
import {store} from "react-notifications-component";
import {currentCompany} from "../utils/consts";

SwiperCore.use([EffectCoverflow, Pagination, A11y]);

const params = {
  loop: false,
  effect: 'coverflow',
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: "auto",
  initialSlide: 2,
  coverflowEffect: {
    rotate: 0,
    stretch: 20,
    depth: 50,
    modifier: 2,
    // slideShadows: true
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  simulateTouch: true,
  slideToClickedSlide: true,
}

const DEFAULT_CARDS = [
  {
    appId: "clean_helper",
    name: '手机清理助手',
    icon: './images/icon_clean_helper.png',
    thumb: './images/thumb_clean_helper_2.png',
    subtitle: '手机清理助手是一款好用的垃圾清理手机工具，可释放存储空间提高性能，使您的手机运行更快。',
    stars: 5,
    btnType: 'download',
    headerColor: '',
    downloadUrl: '#',
  },
];

class AppSwiper extends React.Component {

  state = {
    showQr: false,
    selectedApp: null,
    apps: [],
  }

  componentDidMount() {
    let cards = window.APPS;
    let appList = [];
    if (currentCompany !== null){
      for (const x in currentCompany.apps) {
        for (const y in cards) {
          if (y.appId === x){
            appList.push(y)
          }
        }
      }
    }
    if (!appList || !appList.length) {
      appList = DEFAULT_CARDS;
    }
    this.setState({
      apps: appList,
    })
  }

  onDownloadClick(app) {
    const downloadUrl = app.downloadUrl || '';
    // console.log('跳转AppStore: ' + downloadUrl);
    window.open(downloadUrl,'_blank');
  }

  onBuyClick(app) {
    const { user } = this.props;
    const { hasLogin } = user || {};
    if (!hasLogin) {
      store.addNotification({
        title: '请先登录',
        message: " ",
        type: "warning",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {duration: 2500, onScreen: true}
      });
      return;
    }
    this.setState({
      showQr: true,
      selectedApp: app,
    });
  }

  onSlideChange(swiper) {
    const index = swiper.activeIndex;
    const { apps=[] } = this.state;
    const selectedApp = apps[index];
    if (selectedApp) {
      this.setState({
        selectedApp,
      });
    }
  }

  onCard

  render() {
    const {floatNav} = this.props;
    const {
      showQr,
      selectedApp,
      apps,
    } = this.state;
    return (
      <div>
        {
          !floatNav && (
            <div className="fixed-nav">
              <Nav/>
            </div>
          )
        }
        <Swiper
          {...params}
          className="swiper-apps"
          onSlideChange={swiper => this.onSlideChange(swiper)}
        >
          {
            apps.map(app => (
              <SwiperSlide
                key={app.name}
              >
                <div className="swiper-card">
                  <div className="card-thumb" style={{backgroundImage: `url(${app.thumb})`}}>
                  </div>
                  <div className="card-content">
                    <div className="card-title"><span>{app.name}</span></div>
                    <div className="card-subtitle">{app.subtitle}</div>
                    <div className="card-stars">
                      {
                        new Array(app.stars || 5).fill(1).map((v, i) => <RiStarFill key={i} className="card-star"
                                                                                    size={22} color="#F5A623"/>)
                      }
                    </div>
                    {
                      this.renderButton(app)
                    }
                  </div>
                  {/* ICON */}
                  <div className="card-app-icon">
                    <img src={app.icon}/>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }

        </Swiper>
        {
          showQr && (
            <PayQrCode
              app={selectedApp}
              onClose={() => this.setState({showQr: false})}
            />
          )
        }
        <div className="swiper-pagination"></div>
      </div>
    )
  }

  renderButton(app) {
    const showDownload = app.btnType === 'download';
    if (showDownload) {
      return (
        <div className="card-button" onClick={() => this.onDownloadClick(app)}>
          <div className="card-button-icon">
            <img src="./images/icon_apple.png"/>
          </div>
          <div>
            {/*<span>苹果应用</span>*/}
            <span>点击下载</span>
          </div>
        </div>
      )
    }
    /* Buy */
    return (
      <div className="card-button" onClick={() => this.onBuyClick(app)}>
        <div className="card-button-icon">
          <img src="./images/icon_vip.png"/>
        </div>
        <div>
          {/*<span>购买高级</span>*/}
          <span>立即购买</span>
        </div>
      </div>
    )
  }

}

const mapToProps = ({user, floatNav}) => ({user, floatNav});
export default connect(mapToProps, actions)(AppSwiper);
